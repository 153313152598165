import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { TextWithIcon } from './components/text-with-icon';

const SHeader = styled(HeaderSecond)`
    text-align: center;
    size: 2.25rem;
    margin-bottom: 3.75rem;
`;

const SWrapper = styled.div`
    display: flex;
    gap: 5rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        gap: 2rem;
    `}
`;

const SImageWrapper = styled.div`
    flex: 0.5;
`;

const SCol = styled.div`
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;
`;

const SImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
`;

const ADVANTAGES = [
    'view-devops-consulting-services.advantages-section.acceleration',
    'view-devops-consulting-services.advantages-section.risk',
    'view-devops-consulting-services.advantages-section.monitoring',
    'view-devops-consulting-services.advantages-section.resource',
];

export const MainAdvantagesSection = () => {
    const { formatMessage } = useIntl();
    const businessIntelligenceAdvantage = useStaticQuery(graphql`
        {
            image: file(
                relativePath: {
                    eq: "business-intelligence/business-intelligence-advantages.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <SHeader>
                <FormattedMessage id="view-devops-consulting-services.advantages-section.header" />
            </SHeader>
            <SWrapper>
                <SImageWrapper>
                    <SImage
                        image={
                            businessIntelligenceAdvantage.image.childImageSharp
                                .gatsbyImageData
                        }
                        imgStyle={{
                            objectFit: 'cover',
                            objectPosition: 'contain',
                            width: '100%',
                        }}
                        alt={formatMessage({
                            id: 'view-devops-consulting-services.advantages-section.alt',
                        })}
                    />
                </SImageWrapper>
                <SCol>
                    {ADVANTAGES.map((textId) => (
                        <TextWithIcon key={textId} textId={textId} />
                    ))}
                </SCol>
            </SWrapper>
        </Container>
    );
};
