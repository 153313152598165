import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const DevopsCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="view-devops-consulting-services.cta-section.title">
            <p>
                <FormattedMessage id="view-devops-consulting-services.cta-section.headline" />
            </p>
            <ul>
                <li>
                    <FormattedMessage id="view-devops-consulting-services.cta-section.items.devops-consulting-services" />
                </li>
                <li>
                    <FormattedMessage id="view-devops-consulting-services.cta-section.items.cicd-pipelines" />
                </li>
                <li>
                    <FormattedMessage id="view-devops-consulting-services.cta-section.items.ai-agents-integration" />
                    <a href="https://themobilereality.com/blog/business/ai-agent-development">
                        <FormattedMessage id="view-devops-consulting-services.cta-section.items.ai-agents" />
                    </a>
                    <FormattedMessage id="view-devops-consulting-services.cta-section.items.to-automate-infrastructure" />
                </li>
                <li>
                    <FormattedMessage id="view-devops-consulting-services.cta-section.items.cloud-architecture" />
                </li>
                <li>
                    <FormattedMessage id="view-devops-consulting-services.cta-section.items.cloud-security" />
                </li>
            </ul>
        </CtaSectionExtended>
    );
};
