import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    display: inline;
`;

export const CustomSolutionsSection = () => {
    const { formatMessage } = useIntl();
    const solutions = useStaticQuery(graphql`
        {
            image: file(
                relativePath: {
                    eq: "business-intelligence/business-intelligence-solutions.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    const firstParagraph = formatMessage(
        {
            id: 'view-devops-consulting-services.custom-solutions.paragraph',
        },
        {
            link: (chunks) => (
                <SPrefixLangLink to={`${PATHS.TECHNOLOGIES}/${PATHS.AWS}`}>
                    {chunks}
                </SPrefixLangLink>
            ),
        },
    );

    const secondParagraph = formatMessage(
        {
            id: 'view-devops-consulting-services.custom-solutions.second-paragraph',
        },
        {
            link: (chunks) => (
                <SPrefixLangLink to={PATHS.EXPERTISES}>
                    {chunks}
                </SPrefixLangLink>
            ),
        },
    );

    const thirdParagraph = formatMessage({
        id: 'view-devops-consulting-services.custom-solutions.third-paragraph',
    });

    return (
        <Container>
            <RowWithTextImg
                title="view-devops-consulting-services.custom-solutions.title"
                descriptions={[firstParagraph, secondParagraph, thirdParagraph]}
                isDescriptionFormattedPreviously={true}
                img={solutions.image}
                alt={formatMessage({
                    id: 'view-devops-consulting-services.custom-solutions.alt',
                })}
            />
        </Container>
    );
};
