import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { SectionTiles } from 'components/section-tiles';

import { TILES_DATA } from '../constants';

const SHeader = styled(HeaderSecond)`
    text-align: center;
    size: 2.25rem;
    margin-bottom: 3.75rem;
`;

const STiles = styled(SectionTiles)`
    gap: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .tile {
        max-width: 19rem;
    }
`;

export const SolutionsSection = () => {
    return (
        <Container>
            <SHeader>
                <FormattedMessage id="view-devops-consulting-services.solutions-section.header" />
            </SHeader>
            <STiles tilesData={TILES_DATA} />
        </Container>
    );
};
